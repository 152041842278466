import React from "react"
import Layout from "../components/layout"
import FAQ from "../features/faq/FAQ"


const FAQPage = () => {
  return (
    <Layout>
      <FAQ/>
  </Layout>
)
}

export default FAQPage