import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { HoverTapEffect } from "./AnimationEffects"


const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: "30px",
    height: "30px",
    zIndex: theme.zIndex.drawer + 2,
    "&:hover": {
      cursor: "pointer",
      color: "white"
    }
  }
}))

export interface Props {
  clicked: () => void
}

export default function CloseButton(props: Props) {

  const data = useStaticQuery(graphql`
      query {
          close: file(relativePath: { eq: "close_button.png" }) {
              childImageSharp {
                  fluid(maxWidth: 70) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
              }
          }
      }
  `)

  const classes = useStyles()
  return (
    <div className={classes.root} onClick={props.clicked}>
      <HoverTapEffect>
        <Img fluid={data.close.childImageSharp.fluid}/>
      </HoverTapEffect>
    </div>
  )
}