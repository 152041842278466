import React from "react"
import { Box, Typography } from "@material-ui/core"

export interface Props {
  question: string
  answer: string
}

export default function FAQItem(props: Props) {
  return <Box mb={6}>
    <Typography variant={"body1"} color={"primary"}>{props.question}</Typography>
    <Box mt={1}>
      <Typography variant={"body1"}>{props.answer}</Typography>
    </Box>
  </Box>
}