import * as React from "react"
import { motion } from "framer-motion"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"


const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "uppercase",
    cursor: "pointer",
    marginBottom: theme.spacing(4),
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main
    }
  },
  text: {
    fontFamily: "GothamRoundedLight",
    fontSize: "2rem",
    [theme.breakpoints.up("xl")]: {
      fontSize: "3rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem"
    }
  }
}))

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export interface Props {
  text: string
  clicked: () => void
}

export const MenuItem = (props: Props) => {
  const classes = useStyles()
  return (
    <motion.div
      onClick={props.clicked}
      className={classes.root}
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}>
      <Typography variant={"h1"} className={classes.text}>{props.text}</Typography>
    </motion.div>
  )
}
