import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { getUserProfile } from "../../../utils"
import { Typography } from "@material-ui/core"
import { motion } from "framer-motion"


const menuAspectRatio = 1.34
const menuHeight = 13
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2.5),
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  menu: {
    height: `${menuHeight}px`,
    width: `${menuHeight * menuAspectRatio}px`,
    cursor: "pointer"
  },
  name: {
    marginLeft: theme.spacing(1.5)
  }
}))

export interface Props {
  onClick: () => void
  showWelcome: boolean
  blackIcon: boolean
}


export default function MenuButton(props: Props) {

  const data = useStaticQuery(graphql`
      {
          menu: file(relativePath: {eq: "menu_button.png"}) {
              childImageSharp {
                  fluid(webpQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },

          inverted: file(relativePath: {eq: "menu_black.png"}) {
              childImageSharp {
                  fluid(webpQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)

  const profile = getUserProfile()

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <motion.div
        onClick={props.onClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}>
        <Img className={classes.menu}
             fluid={props.blackIcon ? data.inverted.childImageSharp.fluid : data.menu.childImageSharp.fluid}/>
      </motion.div>
      {props.showWelcome &&
      <Typography className={classes.name} variant={"body2"}>Welcome back, {profile?.displayName}</Typography>}
    </div>
  )
}