import * as React from "react"
import { useEffect } from "react"
import { motion } from "framer-motion"
import { MenuItem } from "./MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { Status } from "../../redux/types"
import { navigate } from "gatsby"
import { logout } from "../login/loginSlice"
import { FAQ, LANDING_PAGE, LOGIN, MEMBERSHIP, PROFILE, REGISTER, WORKOUTS_HOME } from "../routing/Locations"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(7.5),
    marginTop: theme.spacing(15),
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    display: "flex",
    width: "100%",
    height: "100%",
    flexWrap: "wrap",
    overflow: "hidden"
  }
}))

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export interface Props {
  itemClick: () => void
  memberMenu: boolean
}

export const Navigation = (props: Props) => {
  const classes = useStyles()

  const logoutResource = useSelector((state: RootState) => state.login)
  const dispatch = useDispatch()

  useEffect(() => {
    if (logoutResource.status == Status.FINISHED && !logoutResource.data)
      navigate(LANDING_PAGE, {replace: true})
  }, [logoutResource])


  function memberMenu() {
    return <motion.div
      className={classes.root}
      variants={variants}>

      <MenuItem text={"Browse classes"} clicked={() => {
        props.itemClick()
        navigate(WORKOUTS_HOME)
      }}/>

      <MenuItem text={"Profile"} clicked={() => {
        props.itemClick()
        navigate(PROFILE)
      }}/>

      <MenuItem text={"Membership"} clicked={() => {
        props.itemClick()
        navigate(MEMBERSHIP)
      }}/>

      <MenuItem text={"FAQ"} clicked={() => {
        props.itemClick()
        navigate(FAQ)
      }}/>

      <MenuItem text={"Logout"} clicked={() => {
        props.itemClick()
        dispatch(logout())
      }}/>
    </motion.div>
  }

  function nonMemberMenu() {
    return <motion.div
      className={classes.root}
      variants={variants}>
      <MenuItem text={"Home"} clicked={() => {
        props.itemClick()
        navigate(LANDING_PAGE)
      }}/>
      <MenuItem text={"SIGN UP"} clicked={() => {
        props.itemClick()
        navigate(REGISTER)
      }}/>
      <MenuItem text={"LOGIN"} clicked={() => {
        props.itemClick()
        navigate(LOGIN)
      }}/>
      <MenuItem text={"FAQ"} clicked={() => {
        props.itemClick()
        navigate(FAQ)
      }}/>
    </motion.div>
  }

  return props.memberMenu ? memberMenu() : nonMemberMenu()
}

