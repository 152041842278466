import HausLogo from "../../components/HausLogo"
import { Menu, MenuProps } from "./Menu"
import React from "react"
import { useTheme } from "@material-ui/core"

export interface Props extends MenuProps {

}

export default function MenuWithLogo(props: Props) {
  const theme = useTheme()
  return (
    <div style={{ position: "relative", zIndex: theme.zIndex.drawer - 1 }}>
      <HausLogo/>
      <Menu {...props}/>
    </div>
  )
}