import { motion } from "framer-motion"
import useHeight from "../videochat/hooks/useHeight/useHeight"
import CloseButton from "../../components/CloseButton"
import MenuButton from "./MenuButton/MenuButton"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import { Navigation } from "./Navigation"
import Backdrop from "@material-ui/core/Backdrop"

const useStyles = makeStyles((theme) => ({

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.95)",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start"
  }
}))

export interface MenuProps {
  showWelcome: boolean
  blackIcon: boolean
  memberMenu: boolean
}

export const Menu = (props: MenuProps) => {
  const [isOpen, setOpen] = useState(false)
  const height = useHeight()
  const classes = useStyles()

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      style={{ position: "relative" }}
    >
      <MenuButton onClick={() => setOpen(true)} showWelcome={props.showWelcome} blackIcon={props.blackIcon}/>
      <Backdrop className={classes.backdrop} open={isOpen}>
        <CloseButton clicked={() => {
          setOpen(false)
        }}/>
        <Navigation memberMenu={props.memberMenu} itemClick={() => setOpen(false)}/>
      </Backdrop>
    </motion.nav>
  )
}
