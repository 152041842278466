import { makeStyles, useTheme } from "@material-ui/core/styles"
import useHeight from "../videochat/hooks/useHeight/useHeight"
import SEO from "../../components/seo"

import MenuWithLogo from "../menu/MenuWithLogo"
import React, { useEffect, useState } from "react"
import { Box, Typography } from "@material-ui/core"
import FAQItem from "./FAQItem"
import { getUserProfile } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
  },
  body: {
    paddingTop: theme.spacing(5),
    maxWidth: "90%",
    color: "black",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%"
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 0
    }
  }

}))


export default function FAQ() {
  const classes = useStyles()
  const height = useHeight()
  const [memberMenu, setMemberMenu] = useState(false)

  useEffect(() => {
    setMemberMenu(getUserProfile() != null)
  }, [getUserProfile()])


  return  <div style={{ minHeight: height == "0px" ? "100vh" : height }} className={classes.root}>
    <SEO title={"FAQ"}/>

      <MenuWithLogo memberMenu={memberMenu} showWelcome={false} blackIcon={true}/>

      <div className={classes.body}>
        <Typography variant={"h1"} align={"center"}>FREQUENTLY ASKED QUESTIONS</Typography>

        <Box mt={6} pb={3}>
          <FAQItem question={"What is Haus?"} answer={"Haus is a platform that offers streaming of live and on-demand fitness workouts. From HIIT to yoga to core and strength, Haus gives you the ability to quickly and conveniently choose from a catalog of workouts."}/>
          <FAQItem question={"Where can I use Haus?"} answer={"The Haus platform is available for streaming on all devices from mobile, tablets and more."}/>
          <FAQItem question={"Does Haus offer workouts for all fitness levels?"} answer={"Yes! Haus has workouts that are curated for all fitness levels and workout preferences too. You can choose your favourite workout, duration and also the trainer. Workout at your pace anytime, anywhere."}/>
          <FAQItem question={"Do I need equipment?"} answer={"While many workouts don't require special equipment, some require some basic equipment like dumbbells, or a yoga mat."}/>
          <FAQItem question={"Where can I do Haus?"} answer={"Anywhere you want. Anytime you want. The workouts are designed to work in spaces like your living room, your hotel room, the park, or the gym. It's easy to turn any place into a state-of-the-art studio."}/>
        </Box>
      </div>

  </div>

}