import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { css } from "@emotion/core"

const img = css`
  position: absolute;
  left: 22px;
  top: 21px;
  width: 42px;
  height: 98px;
  visibility: hidden;
`

export default function HausLogo() {
  const { logo } = useStaticQuery(
    graphql`
        query {
            logo: file(relativePath: { eq: "logo_haus-white.png" }) {
                childImageSharp {
                    fixed(width: 98) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    `)


  return (

    <span css={img}>
   <Link to="/"><Img fixed={logo.childImageSharp.fixed}/></Link>
   </span>)
}